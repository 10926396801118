function App() {
    return (
        <div>
            <div style={{
                height: '50px',
                background: 'repeating-linear-gradient( 45deg, goldenrod, goldenrod 20px, black 20px, black 40px)'
            }}>
            </div>
            <div class="w3-container w3-white w3-center">
                <h1>
                    <img src="/CollisionCoreSpinner.gif" alt="Collision Core spinning logo" />
                    This site is currently unavailable
                    <img src="/CollisionCoreSpinner.gif" alt="Collision Core spinning logo" />
                </h1>

                <p class="w3-xlarge">We apologize for the inconvenience while we are checking under the hood.</p>

                <p class="w3-xlarge">The site will be available Monday September 23, 2024.</p>

                <img alt="I'm busy" src="/UnderMaintenance.png" />
            </div>
            <div style={{
                height: '50px',
                background: 'repeating-linear-gradient( -45deg, goldenrod, goldenrod 20px, black 20px, black 40px)'
            }}>
            </div>
        </div>
    );
}

export default App;
